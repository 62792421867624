import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily:'Roboto, Helvetica, Arial, sans-serif, serif'
  },
  palette: {
    primary: {
      main: '#31d850',
    },
    secondary: {
      main: '#1c59e0',
    },
    background: {
      default: '#dbe0f1',
      paper: '#4d91f5',
    },
  },
});

export default theme;
